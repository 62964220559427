import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Flex } from 'rebass'
import { Link, useIntl } from 'gatsby-plugin-intl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import BurgerIcon from '../../assets/svg/burgerMenu.inline.svg'
import useClickOutside from '../../hooks/useClickOutside'
import { links } from './utils'
import { ButtonClient, HeaderLink } from './Header.styled'
import CloseButton from '../common/CloseButton'

const BurgerMenu = ({ language }) => {
  const menuRef = useRef(null)
  const intl = useIntl()

  const [isOpenMenu, openMenu] = useState(false)

  useClickOutside(menuRef, () => openMenu(false))

  return (
    <ContainerMenu>
      <ButtonMenu onClick={() => openMenu(true)}>
        <BurgerIcon />
      </ButtonMenu>
      <Menu ref={menuRef}>
        <MenuContainer className={isOpenMenu ? 'open' : ''}>
          <Flex justifyContent="flex-end">
            <CloseButton onClick={() => openMenu(false)} />
          </Flex>
          <MenuContent>
            {links.map(({ key, path, modal }) => (
              <HeaderLinkDark
                key={key}
                variant="nav"
                to={modal ? path : `/${language}${path}`}
                as={modal ? Link : AnchorLink}
                state={{
                  modal,
                }}
              >
                {intl.formatMessage({ id: key })}
              </HeaderLinkDark>
            ))}
            <SectionMenu>
              <ButtonAccount href="https://admin.2dine.io/" target="_blank">
                {intl.formatMessage({ id: 'account' })}
              </ButtonAccount>
            </SectionMenu>
          </MenuContent>
        </MenuContainer>
      </Menu>
    </ContainerMenu>
  )
}

const ContainerMenu = styled.div`
  display: none;
  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    display: initial;
  }
`
const ButtonMenu = styled.button`
  background-color: transparent;
  border: none;
  &:hover > svg {
    fill: rgba(255, 255, 255, 1);
  }
  svg {
    fill: rgba(255, 255, 255, 0.7);
  }
`
const Menu = styled.div`
  position: absolute;
  z-index: 2000;
  width: calc(100% - 20px);
  top: 12px;
  left: 10px;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  perspective: 2000px;
`

const MenuContainer = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    0 -18px 60px -10px rgba(0, 0, 0, 0.025);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  font-size: 17px;
  line-height: 40px;
  white-space: nowrap;
  flex-grow: 1;
  opacity: 0;
  transform: scale(0.95);
  transform-origin: top right;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 0.25s;

  &.open {
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }
`

const MenuContent = styled.div`
  display: block;
  padding: 15px 20px;
`

const SectionMenu = styled.div`
  border-top: 2px solid ${props => props.theme.colors.neutralLighter};
  padding: 15px 20px;
`

const ButtonAccount = styled(ButtonClient)`
  color: ${props => props.theme.colors.white};

  background: ${props => props.theme.colors.themePrimary};
`

const HeaderLinkDark = styled(HeaderLink)`
  color: rgba(0, 0, 0, 0.7);
  display: block;
  font-size: ${props => props.theme.fontSizes[2]}px;
  &:hover {
    color: rgba(0, 0, 0, 1);
  }
`

export default BurgerMenu

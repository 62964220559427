import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import Header from './header'
import Footer from './footer'
import defaultImage from '../assets/images/2dine-icon.png'
import { useIntl } from 'gatsby-plugin-intl'

const Layout = ({ children, pageContext: { intl } }) => {
  const intlLanguage = useIntl()
  const title = intlLanguage.formatMessage({ id: 'title' })
  const description = intlLanguage.formatMessage({ id: 'description' })

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'es',
        }}
        title={title}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={defaultImage} />
        {/* <meta
              name="google-site-verification"
              content="r41wqXqJhzNSqaaqv77BR28wyM10rtamd7p-bIVlqRE"
            /> */}

        <meta property="og:url" content="https://landing.2dine.io" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={defaultImage} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@2dinemx" />
        <meta
          name="twitter:site"
          content="https://mobile.twitter.com/2dinemx"
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={defaultImage} />
      </Helmet>
      <Header siteTitle={title} {...intl} />
      <GlobalContent>
        <Main>
          <PrimarySection id="primary">{children}</PrimarySection>
        </Main>
      </GlobalContent>
      <Footer />
    </>
  )
}

const GlobalContent = styled.div`
  flex-grow: 1;
`

const Main = styled.main`
  position: relative;
  overflow: hidden;
  display: block;
`

const PrimarySection = styled.section`
  position: relative;
  overflow: hidden;
  display: block;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

export const links = [
  {
    key: 'home',
    path: '/',
  },
  // {
  //   key: 'about_us',
  //   path: '/#aboutUs',
  // },
  {
    key: 'features',
    path: '/#features',
  },
  {
    key: 'how_works',
    path: '/#how_works',
  },
  // {
  //   key: 'pricing',
  //   path: '/#pricing',
  // },
  {
    key: 'footer_contact_us',
    path: '/contact-us',
    modal: true,
  },
  {
    key: 'demo',
    path: '/demo',
  },
]

import React from 'react'
import { Flex, Box } from 'rebass'
import styled from '@emotion/styled'
import { Link as GatsbyLink, useIntl } from 'gatsby-plugin-intl'

import FooterHeader from '../../assets/svg/footerHeader.inline.svg'
import Facebook from '../../assets/svg/facebook.inline.svg'
import Instagram from '../../assets/svg/instagram.inline.svg'
import LinkedIn from '../../assets/svg/linkedin.inline.svg'

const Footer = () => {
  const intl = useIntl()
  return (
    <footer>
      <FooterHeader />
      <ContainerFooter px={2} justifyContent="center">
        <Box p={2} width={1} style={{ maxWidth: '1040px' }}>
          <Flex flexDirection="column">
            <Flex alignItems="flex-start" flexWrap="wrap">
              <Box p={1} width={[1, 1 / 3]}>
                <Title>2Dine</Title>
                <Link as="a" href="mailto:contacto@2dine.io">
                  contacto@2dine.io
                </Link>
                <Box py={1} width={[1]}>
                  <Link as="a" href="tel:+525562081152">
                    +52 55 6208 1152
                  </Link>
                  <Link as="a" href="tel:+529981477256">
                    +52 998 147 7256
                  </Link>
                </Box>
                <ContainerSocialMedia>
                  <Link
                    as="a"
                    href="https://www.facebook.com/2dinemx/"
                    aria-label="facebook"
                    target="_blank"
                  >
                    <Facebook />
                  </Link>
                  <Link
                    as="a"
                    href="https://www.instagram.com/2dinemx/"
                    aria-label="instagram"
                    target="_blank"
                  >
                    <Instagram />
                  </Link>
                  <Link
                    as="a"
                    href="https://linkedin.com/showcase/2dineapp"
                    aria-label="linkedin"
                    target="_blank"
                  >
                    <LinkedIn />
                  </Link>
                </ContainerSocialMedia>
              </Box>
              <Box p={1} width={[1 / 2, 1 / 3]}>
                <Title>{intl.formatMessage({ id: 'footer_support' })}</Title>
                <Flex flexDirection="column">
                  <Link>FAQ</Link>
                  <Link>Chat</Link>
                  <Link
                    as={GatsbyLink}
                    to="/contact-us"
                    state={{
                      modal: true,
                    }}
                  >
                    {intl.formatMessage({ id: 'footer_contact_us' })}
                  </Link>
                  <Link>
                    {intl.formatMessage({ id: 'footer_notice_of_privacy' })}
                  </Link>
                  <Link as={GatsbyLink} to="/terms">
                    {intl.formatMessage({ id: 'footer_terms_and_privacy' })}
                  </Link>
                </Flex>
              </Box>
              <Box p={1} width={[1 / 2, 1 / 3]}>
                <Title>{intl.formatMessage({ id: 'footer_payments' })}</Title>
                <Flex flexDirection="column">
                  <Link>Mastercard</Link>
                  <Link>Visa</Link>
                  <Link>American Express</Link>
                  <Link>Google Pay</Link>
                  <Link>Apple Pay</Link>
                  <Link>Oxxo Pay</Link>
                </Flex>
              </Box>
            </Flex>
            <Box width={1} my={3}>
              <FooterDetails alignItems="space-between">
                <SubText>
                  Copyright © 2020 2dine, Inc.{' '}
                  {intl.formatMessage({ id: 'footer_rights' })}
                </SubText>
              </FooterDetails>
            </Box>
          </Flex>
        </Box>
      </ContainerFooter>
    </footer>
  )
}

const ContainerFooter = styled(Flex)`
  background: linear-gradient(to right, #ffaa00 40%, #ff3270 94%);
  margin-top: -7px;
`

const Title = styled.div`
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes[2]}px;
  color: ${props => props.theme.colors.white};
  margin-bottom: 12px;
`

const FooterDetails = styled(Flex)`
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`

const SubText = styled.span`
  font-size: ${props => props.theme.fontSizes[0]}px;
  color: ${props => props.theme.colors.neutralLight};
`

const Link = styled.span`
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  font-size: ${props => props.theme.fontSizes[1]}px;
  transition: all 0.1s ease-in-out;
  display: block;
  cursor: pointer;
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  svg {
    fill: rgba(255, 255, 255, 1);
    &:hover {
      fill: rgba(255, 255, 255, 0.7);
    }
  }
`

const ContainerSocialMedia = styled(Flex)`
  margin-top: 16px;
  a {
    margin-right: 12px;
  }
  a:last-child {
    margin: 0;
  }
`

export default Footer

import React from 'react'
import styled from '@emotion/styled'

import CloseIcon from '../../assets/svg/close.inline.svg'

const CloseButton = props => {
  return (
    <CloseMenuLink {...props}>
      <CloseIcon />
    </CloseMenuLink>
  )
}

const CloseMenuLink = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  padding: 12px 14px;
  cursor: pointer;

  svg {
    fill: ${props => props.theme.colors.themePrimary};
  }
`

export default CloseButton

import React, { useRef, useState } from 'react'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'
import styled from '@emotion/styled'

import useClickOutside from '../../hooks/useClickOutside'

const Languages = () => {
  const menuRef = useRef(null)

  const [isOpenMenu, openMenu] = useState(false)

  useClickOutside(menuRef, () => openMenu(false))

  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => (
        <Dropdown ref={menuRef} onClick={() => openMenu(true)}>
          <span>{currentLocale}</span>
          <DropdownContent display={isOpenMenu ? 'block' : 'none'}>
            {languages.map((lang, index) => (
              <LanguageItem key={index} onClick={() => changeLocale(lang)}>
                {lang}
              </LanguageItem>
            ))}
          </DropdownContent>
        </Dropdown>
      )}
    </IntlContextConsumer>
  )
}

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 16px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 1);

  cursor: pointer;
  > span {
    font-size: 14px;
  }
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`

const DropdownContent = styled.div`
  display: ${props => props.display};
  background: ${props => props.theme.colors.white};
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    0 -18px 60px -10px rgba(0, 0, 0, 0.025);
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  transition: all 0.1s ease-in-out;
  padding: 8px 0 4px 0;
`

const LanguageItem = styled.span`
  text-transform: uppercase;

  padding: 4px 12px;
  cursor: pointer;
  display: block;
  color: ${props => props.theme.colors.black};
  font-size: 15px;
  &:hover {
    background-color: ${props => props.theme.colors.neutralLight};
  }
`

export default Languages

import React from 'react'
import styled from '@emotion/styled'
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { Flex, Box } from 'rebass'
import {
  HeaderBackground,
  ContainerFigures,
  BackgroundFigure,
  HeaderContainer,
  HeaderLink,
  ButtonAccount,
} from './Header.styled'
import { links } from './utils'
import HeaderIcon from './HeaderIcon'
import BurgerMenu from './BurgerMenu'
import Languages from './Languages'

const Header = ({ originalPath, language }) => {
  const intl = useIntl()

  return (
    <>
      <HeaderBackground height={originalPath === '/' ? '100%' : 'initial'}>
        <ContainerFigures px={2} justifyContent="#FFAA00">
          <BackgroundFigure index={0} background="#FFAA00" />
          <BackgroundFigure index={1} background="#FFAA00" />
          <BackgroundFigure />
          <BackgroundFigure />
        </ContainerFigures>
      </HeaderBackground>
      <HeaderContainer>
        <Flex px={2} justifyContent="center">
          <Box p={3} width={[1, 1, 1, 2 / 3]} style={{ maxWidth: '1040px' }}>
            <Flex px={2} alignItems="center">
              <Link to="/" aria-label="Home">
                <HeaderIcon />
              </Link>
              <Box mx="auto" />
              <ContentHeader mx={-2} alignItems="center">
                {links.map(({ key, path, modal = false }) => (
                  <HeaderLink
                    key={key}
                    variant="nav"
                    to={modal ? path : `/${language}${path}`}
                    as={modal ? Link : AnchorLink}
                    state={{
                      modal,
                    }}
                  >
                    {intl.formatMessage({ id: key })}
                  </HeaderLink>
                ))}
                <ButtonAccount href="https://admin.2dine.io/" target="_blank">
                  <FormattedMessage id="account" />
                </ButtonAccount>
                <Languages />
              </ContentHeader>
              <BurgerMenu language={language} />
            </Flex>
          </Box>
        </Flex>
      </HeaderContainer>
    </>
  )
}

const ContentHeader = styled(Flex)`
  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    display: none;
  }
`
export default Header

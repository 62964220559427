import styled from '@emotion/styled'
import { Flex } from 'rebass'
import Img from 'gatsby-image'

const HeaderContainer = styled.header`
  position: absolute;
  left: 0;
  top: 10px;
  right: 0;
  height: 50px;
  z-index: 1;
  perspective: 2000px;
`
const HeaderLink = styled.span`
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  margin: 0 16px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  transition: all 0.1s ease-in-out;
  font-weight: semibold;
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`
const HeaderBackground = styled.div`
  width: 100%;
  height: ${props => props.height};
  overflow: hidden;
  transform: skewY(-5deg);
  transform-origin: 0;
  background: linear-gradient(150deg, #f7ffa5 10%, #ffaa00 40%, #ff3270 94%);
  position: absolute;
  min-height: 600px;
  max-height: 750px;
`

const HeaderContent = styled.section`
  height: 760px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const HeaderTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes[4]}px;
  color: ${props => props.theme.colors.white};
  font-weight: 300;
`

const HeaderText = styled.p`
  max-width: 80%;
  font-weight: 300;
  font-size: ${props => props.theme.fontSizes[1]}px;
  color: ${props => props.theme.colors.white};
  letter-spacing: 0.05em;
  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    max-width: 100%;
  }
`

const ContainerFigures = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  right: -300px;
  bottom: 0;
`

const BackgroundFigure = styled.span`
  flex: 1;
  height: 100%;
  min-width: 180px;
  transform: skew(-55deg);
  overflow: hidden;
  margin: 12px;
  /* background: ${props => props.background}; */
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
`

const ContainerImages = styled.div`
  padding: 16;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin-top: 35%;
`

const PhoneContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 38px;
  background: #fff;
  box-shadow: inset 0 4px 7px 1px #fff,
    inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14),
    0 10px 20px rgba(0, 21, 64, 0.05);
  width: 225px;
  height: 467px;
`

const PhoneContainerDemo = styled(PhoneContainer)`
  height: 667px;
  width: 340px;
  margin: auto;
`

const ImageContent = styled(Img)`
  object-fit: contain;
  height: 375px;
  width: 211px;
`
const ButtonAccount = styled.a`
  text-decoration: none;
  background: transparent;
  border-radius: 20px;
  border: 1px solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};
  transition: all 0.2s ease-in-out;
  padding: 6px 22px;
  margin-left: 16px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-weight: semibold;
  cursor: pointer;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.8);
    color: rgba(255, 255, 255, 0.8);
  }
`

const ButtonClient = styled.a`
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: ${props => props.theme.colors.themePrimary};
  text-decoration: none;
  transition: all 0.15s ease;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`

export {
  HeaderContainer,
  HeaderLink,
  HeaderBackground,
  HeaderContent,
  HeaderTitle,
  HeaderText,
  BackgroundFigure,
  ContainerFigures,
  ContainerImages,
  PhoneContainer,
  PhoneContainerDemo,
  ImageContent,
  ButtonAccount,
  ButtonClient,
}
